// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-complete-js": () => import("./../../../src/pages/complete.js" /* webpackChunkName: "component---src-pages-complete-js" */),
  "component---src-pages-disclaimer-mdx": () => import("./../../../src/pages/disclaimer.mdx" /* webpackChunkName: "component---src-pages-disclaimer-mdx" */),
  "component---src-pages-guide-mdx": () => import("./../../../src/pages/guide.mdx" /* webpackChunkName: "component---src-pages-guide-mdx" */),
  "component---src-pages-help-center-mdx": () => import("./../../../src/pages/help-center.mdx" /* webpackChunkName: "component---src-pages-help-center-mdx" */),
  "component---src-pages-index-mdx": () => import("./../../../src/pages/index.mdx" /* webpackChunkName: "component---src-pages-index-mdx" */),
  "component---src-pages-informed-consent-mdx": () => import("./../../../src/pages/informed-consent.mdx" /* webpackChunkName: "component---src-pages-informed-consent-mdx" */),
  "component---src-pages-privacy-policy-mdx": () => import("./../../../src/pages/privacy-policy.mdx" /* webpackChunkName: "component---src-pages-privacy-policy-mdx" */),
  "component---src-pages-terms-and-conditions-mdx": () => import("./../../../src/pages/terms-and-conditions.mdx" /* webpackChunkName: "component---src-pages-terms-and-conditions-mdx" */)
}

