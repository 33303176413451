import React, { useContext } from "react"
import classNames from "classnames"
import { useStaticQuery, graphql, navigate } from "gatsby"

import Hero from "layout/Hero"
import Container from "layout/Container"
import Button from "elements/Button"
import CompleteProfileModal from "../HomeBanner/CompleteProfileModal"

import styles from "../utils/staticPages.module.scss"

import { getSignedInUser, hasRoles } from "../../Auth/services/user"
import { AppContext } from "../../../context/AppContext"

const HomeBanner = () => {
  const { dispatch } = useContext(AppContext)
  const user = getSignedInUser()?.authUser

  const data = useStaticQuery(graphql`
    {
      heart: file(relativePath: { eq: "landing_page/landing_rx-beat.png" }) {
        childImageSharp {
          fluid(
            duotone: { highlight: "#ffffff", shadow: "#0d6d6e" }
            toFormat: PNG
            quality: 90
            maxWidth: 1000
          ) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  const heart = data.heart.childImageSharp.fluid.src

  const handleClick = () => {
    if (user && hasRoles()) {
      navigate("/self/history")
    } else if (user && !hasRoles()) {
      dispatch({
        type: "SHOW_MODAL",
        payload: {
          heading: "Complete your profile",
          isCard: true,
          headerClass: `has-text-info has-background-info-light has-text-weight-bold is-size-5`,
          content: <CompleteProfileModal />,
        },
      })
    } else {
      navigate("/verify-email")
    }
  }

  return (
    <Hero
      size="medium"
      background={{
        image: heart,
        position: "center",
      }}
    >
      <Container isCentered desktop={10} fullhd={8}>
        <Container mobile={12} tablet={8} desktop={8} padding={0}>
          <h1 className="title is-size-1 has-text-primary">
          We are grateful to have served Aloricans for the past three years. However, in 2025, Alorica will be having a new provider.
          </h1>

          <h3 className="subtitle is-size-4 pt-2">
          We are grateful to have served Aloricans for the past three years. However, in 2025, Alorica will be having a new provider. Please contact your HR for Wellness needs.          </h3>

          {/* <Button
            color="primary"
            size="medium"
            onClick={handleClick}
            className={classNames(styles["home__buttonIsFullwidth"])}
          >
            Request Teleconsult
          </Button> */}
        </Container>
      </Container>
    </Hero>
  )
}

export default HomeBanner
