import React, { Fragment } from "react"

import Hero from "layout/Hero"
import Container from "layout/Container"
import HomeMediaContainer from "./HomeMediaContainer"
import HomeProcessArrow from "./HomeProcessArrow"

import useHomeImages from "../hooks/useHomeImages"
import howProgramWorks from "../utils/howProgramWorks.json"

const HomeHowProgramWorks = () => {
  const data = useHomeImages()
  const arrow = data.arrow.childImageSharp.fixed

  return (
    null
    // <Hero size="small">
    //   {/* <Container isCentered desktop={10} fullhd={8}>
    //     <h3 className="my-3 has-text-centered has-text-primary">
    //       Here’s how it works
    //     </h3>
    //     <div className="columns is-centered mb-3">
    //       {howProgramWorks.map((item, index) => (
    //         <Fragment>
    //           <HomeMediaContainer
    //             title={item?.title}
    //             description={item?.description}
    //             image={data[item?.imageName]?.childImageSharp?.fixed}
    //           />
    //           {index < howProgramWorks?.length - 1 && (
    //             <HomeProcessArrow arrowImage={arrow} />
    //           )}
    //         </Fragment>
    //       ))}
    //     </div>
    //   </Container> */}
    // </Hero>
  )
}

export default HomeHowProgramWorks
